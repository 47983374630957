<div
        *ngIf="item.driver_settings?.enabled"
        class="settings-item driver-badge"
>
    <lta-svg-icon
            *ngIf="!item.driver_settings.settings.write_flag"
            [icon]="'driver_read'"
            class="item__icon"
            size="20"
    ></lta-svg-icon>
    <lta-svg-icon
            *ngIf="item.driver_settings.settings.write_flag"
            [icon]="'driver_write'"
            class="item__icon"
            size="20"
    ></lta-svg-icon>
    <span class="item__name text-ellipsis">{{item.driver_settings.settings.name}}</span>
</div>
<div
        *ngIf="item.calculate_settings?.enabled"
        class="settings-item calculation-badge"
>
    <lta-svg-icon
            [icon]="'calc'"
            [offset]="{x: 1, y: 1}"
            class="item__icon"
            size="20"
    ></lta-svg-icon>
</div>
<div
        *ngIf="item.imitation_settings?.enabled"
        class="settings-item imitation-badge"
>
    <lta-svg-icon
            [icon]="'imitation'"
            [offset]="{x: 1, y: 1}"
            class="item__icon"
            size="20"
    ></lta-svg-icon>
    <span class="description">{{this.imitationTypes[item.imitation_settings?.settings.type]}}</span>
</div>

<div class="settings-item event-badge" *ngIf="item['have_events']">
    <lta-svg-icon
            icon="alerts"
            [offset]="{x: 1, y: 1}"
            class="item__icon yellow"
            size="20"
    />
</div>
