import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {EventSetsHttpService, EventTypesHttpService, IEventSet, IEventType} from "@atl/lacerta-ui-common";
import {map, tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class EventSetsService {
    eventSetsMap: Map<number, IEventSet> = new Map<number, IEventSet>()
    private eventSetsSubject: BehaviorSubject<IEventSet[]> = new BehaviorSubject<IEventSet[]>([]);
    eventSets$: Observable<IEventSet[]> = this.eventSetsSubject.asObservable()
        .pipe(map(values => this.sortSets(values)))
    private activeEventSetSubject: BehaviorSubject<IEventSet> = new BehaviorSubject<IEventSet>(null);
    activeEventSet$: Observable<IEventSet> = this.activeEventSetSubject.asObservable();
    eventClassesMap: Map<number, IEventType> = new Map<number, IEventType>()

    constructor(
        private eventSetsHttp: EventSetsHttpService,
        private eventTypesHttp: EventTypesHttpService,
    ) {
    }

    private sortSets(eventSets: IEventSet[]): IEventSet[] {
        return eventSets.sort((a, b) => {
            let textA = a.name.toUpperCase();
            let textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
    }

    getEventClasses(): Observable<IEventType[]> {
        return this.eventTypesHttp.getAllEventTypes()
            .pipe(tap(values => {
                values.forEach(val => this.eventClassesMap.set(val.id, val))
            }))
    }

    getEventSets(): Observable<IEventSet[]> {
        return this.eventSetsHttp.getEventSets()
            .pipe(tap(values => {
                this.eventSetsSubject.next(values);
                values.forEach(val => this.eventSetsMap.set(val.id, val))
            }))
    }

    getEventSetById(id: number): IEventSet {
        return this.eventSetsMap.get(id);
    }

    setActiveEventSet(eventSet: IEventSet) {
        this.activeEventSetSubject.next(eventSet);
    }

    addBlankEventSet(): void {
        this.activeEventSetSubject.next({
            id: null,
            name: null,
            description: null,
            model_id: null,
            event_settings: [],
        })
    }

    createEventSet(eventSet: IEventSet): Observable<IEventSet> {
        return this.eventSetsHttp.createEventSet(eventSet)
            .pipe(
                tap(value => {
                    this.activeEventSetSubject.next(value)
                    this.eventSetsSubject.next([...this.eventSetsSubject.value, value])
                    this.eventSetsMap.set(value.id, value)
                })
            )
    }

    updateEventSet(eventSet: IEventSet): Observable<IEventSet> {
        return this.eventSetsHttp.updateEventSet(eventSet)
            .pipe(
                tap(value => {
                    this.activeEventSetSubject.next(value);
                    this.eventSetsSubject.next(this.eventSetsSubject.value.map(v => v.id === value.id ? value : v));
                    this.eventSetsMap.set(value.id, value);
                })
            )
    }

    deleteEventSet(eventSet: IEventSet): Observable<void> {
        return this.eventSetsHttp.deleteEventSet(eventSet.id).pipe(tap(() => {
            this.activeEventSetSubject.next(null);
            this.eventSetsSubject.next(this.eventSetsSubject.value.filter(v => v.id !== eventSet.id));
            this.eventSetsMap.delete(eventSet.id);
        }))
    }
}
