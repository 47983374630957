import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {SaveBtnState} from "@atl/lacerta-ui-common";

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    private saveBtnSubject = new Subject()
    public saveBtn$ = this.saveBtnSubject.asObservable()

    private isSaveBtnDisabledSubject = new BehaviorSubject<boolean>(true)
    public isSaveBtnDisabled$ = this.isSaveBtnDisabledSubject.asObservable()

    private saveBtnStateSubject = new BehaviorSubject<SaveBtnState>('SAVE')
    public saveBtnState$ = this.saveBtnStateSubject.asObservable()

    constructor() {
    }

    public setSaveBtnState(state: SaveBtnState) {
        this.saveBtnStateSubject.next(state)
    }

    public setSaveBtnStatus(isDisabled: boolean) {
        this.isSaveBtnDisabledSubject.next(isDisabled)
    }

    public pressSave() {
        this.saveBtnSubject.next(null)
    }
}
